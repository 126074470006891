var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"register-page"},[_c('Backbar',{attrs:{"title":_vm.$t('editProfile.title'),"is-clean":""},on:{"onBackClick":function($event){return _vm.$router.go(-1)}}}),_c('div',{staticClass:"register-form"},[_c('ValidationObserver',{ref:"registerForm"},[_c('div',{},[_c('ValidationProvider',{attrs:{"rules":"required","name":"firstName"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('Input',{attrs:{"placeholder":_vm.$t('registerPage.firstName')},model:{value:(_vm.registration.firstName),callback:function ($$v) {_vm.$set(_vm.registration, "firstName", $$v)},expression:"registration.firstName"}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"has-margin-top-25"},[_c('ValidationProvider',{attrs:{"rules":"required","name":"lastName"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('Input',{attrs:{"placeholder":_vm.$t('registerPage.lastName')},model:{value:(_vm.registration.lastName),callback:function ($$v) {_vm.$set(_vm.registration, "lastName", $$v)},expression:"registration.lastName"}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"has-margin-top-25"},[_c('ValidationProvider',{attrs:{"rules":"required|min:12","name":"mobile"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('MaskInput',{attrs:{"data-vv-validate-on":"change","placeholder":_vm.$t('registerPage.phoneNumber'),"mask":'###-###-####'},model:{value:(_vm.registration.mobile),callback:function ($$v) {_vm.$set(_vm.registration, "mobile", $$v)},expression:"registration.mobile"}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"has-text-black87 _fs-14 has-margin-top-25"},[_vm._v(_vm._s(_vm.$t('registerPage.gender')))]),_c('div',{staticClass:"has-margin-top-15"},[_c('ValidationProvider',{attrs:{"rules":"required","name":"gender"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"is-flex is-flex-wrap-wrap"},[_c('RadioButton',{attrs:{"name":"gender","native-value":"Male"},model:{value:(_vm.registration.gender),callback:function ($$v) {_vm.$set(_vm.registration, "gender", $$v)},expression:"registration.gender"}},[_vm._v(" "+_vm._s(_vm.$t('registerPage.male'))+" ")]),_c('RadioButton',{attrs:{"name":"gender","native-value":"Female"},model:{value:(_vm.registration.gender),callback:function ($$v) {_vm.$set(_vm.registration, "gender", $$v)},expression:"registration.gender"}},[_vm._v(" "+_vm._s(_vm.$t('registerPage.female'))+" ")]),_c('RadioButton',{attrs:{"name":"gender","native-value":"Prefer not to say"},model:{value:(_vm.registration.gender),callback:function ($$v) {_vm.$set(_vm.registration, "gender", $$v)},expression:"registration.gender"}},[_vm._v(" "+_vm._s(_vm.$t('registerPage.preferNotToSay'))+" ")])],1),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"has-text-black87 _fs-14 has-margin-top-25"},[_vm._v(_vm._s(_vm.$t('registerPage.dateOfBirth')))]),_c('div',{staticClass:"has-margin-top-15"},[_c('ValidationProvider',{attrs:{"rules":"required","name":"birthDate"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('DropdownDatePicker',{attrs:{"defaultDate":_vm.registration.birthDate,"required":""},model:{value:(_vm.registration.birthDate),callback:function ($$v) {_vm.$set(_vm.registration, "birthDate", $$v)},expression:"registration.birthDate"}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"has-text-black87 _fs-14 has-margin-top-25"},[_vm._v(_vm._s(_vm.$t('registerPage.address')))]),_c('div',{staticClass:"has-margin-top-20"},[_c('ValidationProvider',{attrs:{"name":"address"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('Input',{attrs:{"placeholder":_vm.$t('registerPage.addressDetail')},model:{value:(_vm.registration.address),callback:function ($$v) {_vm.$set(_vm.registration, "address", $$v)},expression:"registration.address"}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"has-margin-top-15"},[_c('ValidationProvider',{attrs:{"name":"province"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SelectProvince',{attrs:{"placeholder":_vm.$t('registerPage.province')},on:{"change":_vm.onProvinceChange},model:{value:(_vm.provinceId),callback:function ($$v) {_vm.provinceId=$$v},expression:"provinceId"}},_vm._l((_vm.provinces),function(province,index){return _c('option',{key:index,domProps:{"value":province.id}},[_vm._v(" "+_vm._s(_vm.$i18n.locale === 'th' ? province.name_th : province.name_en)+" ")])}),0),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"has-margin-top-15"},[_c('ValidationProvider',{attrs:{"name":"district"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SelectProvince',{attrs:{"placeholder":_vm.$t('registerPage.district')},on:{"change":_vm.onDistrictChange},model:{value:(_vm.districtId),callback:function ($$v) {_vm.districtId=$$v},expression:"districtId"}},_vm._l((_vm.districts),function(district,index){return _c('option',{key:index,domProps:{"value":district.id}},[_vm._v(" "+_vm._s(_vm.$i18n.locale === 'th' ? district.name_th : district.name_en)+" ")])}),0),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"has-margin-top-15"},[_c('ValidationProvider',{attrs:{"name":"subDistrict"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SelectProvince',{attrs:{"placeholder":_vm.$t('registerPage.subDistrict')},on:{"change":_vm.onSubDistrictChange},model:{value:(_vm.subDistrictId),callback:function ($$v) {_vm.subDistrictId=$$v},expression:"subDistrictId"}},_vm._l((_vm.subDistricts),function(subDistrict,index){return _c('option',{key:index,domProps:{"value":subDistrict.id}},[_vm._v(" "+_vm._s(_vm.$i18n.locale === 'th' ? subDistrict.name_th : subDistrict.name_en)+" ")])}),0),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"has-margin-top-15"},[_c('ValidationProvider',{attrs:{"name":"postcode"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('MaskInput',{attrs:{"placeholder":_vm.$t('registerPage.postcode'),"mask":'#####'},model:{value:(_vm.registration.postCode),callback:function ($$v) {_vm.$set(_vm.registration, "postCode", $$v)},expression:"registration.postCode"}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"has-text-black87 _fs-14 has-margin-top-25"},[_vm._v(_vm._s(_vm.$t('registerPage.healthCondition')))]),_c('div',{staticClass:"has-margin-top-15"},[_c('AnswerList',{model:{value:(_vm.healthProblems),callback:function ($$v) {_vm.healthProblems=$$v},expression:"healthProblems"}})],1),_c('ValidationProvider',{attrs:{"rules":{'required': _vm.isOtherHealthProblem},"name":"otherHealthProblem"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOtherHealthProblem),expression:"isOtherHealthProblem"}],staticClass:"has-margin-top-5"},[_c('Input',{attrs:{"placeholder":"Other"},model:{value:(_vm.otherHealthProblem),callback:function ($$v) {_vm.otherHealthProblem=$$v},expression:"otherHealthProblem"}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('div',{staticClass:"has-text-black87 _fs-14 has-margin-top-25"},[_vm._v(_vm._s(_vm.$t('registerPage.interestHealthcare')))]),_c('div',{staticClass:"has-margin-top-15"},[_c('AnswerList',{model:{value:(_vm.healthInteresting),callback:function ($$v) {_vm.healthInteresting=$$v},expression:"healthInteresting"}})],1),_c('ValidationProvider',{attrs:{"rules":{'required': _vm.isOtherHealthInteresting},"name":"otherHealthInteresting"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOtherHealthInteresting),expression:"isOtherHealthInteresting"}],staticClass:"has-margin-top-5"},[_c('Input',{attrs:{"placeholder":"Other"},model:{value:(_vm.otherHealthInteresting),callback:function ($$v) {_vm.otherHealthInteresting=$$v},expression:"otherHealthInteresting"}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('div',{staticClass:"has-margin-top-15"},[_c('div',{staticClass:"is-flex align-items-center justify-center has-margin-top-10"},[_c('RoundButton',{on:{"click":_vm.submit}},[_vm._v(_vm._s(_vm.$t('editProfile.finishEditing')))])],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }